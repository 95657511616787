.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    // height: 32px;
    text-align: center;
    margin: auto 4px;
    // color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    // border-radius: 16px;
    // line-height: 1.43;
    // font-size: 13px;
    // min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
